import React from 'react';
import Title from '../layouts/Title';
import { projectOne, projectTwo, projectThree, projectFour, projectFive, projectSix, projectSeven } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="Check these out"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Duality"
          des="An online FPS game in Unity made as the final project for Game Development Course. I was responsible for the game logic and the online architecture of the project."
          src={projectOne}
          embedID="DnQ8AAoJk1A"
        />
        <ProjectsCard
          title="Capture The Red Cube"
          des="An online hidden object game in Unity made as assignment 2 for Online Game Development course. This was an individual project and was finished in under a week."
          src={projectTwo}
          embedID="nVpiTBMPXzg"
        />
        <ProjectsCard
          title="Energynomics"
          des="A card game made as the final project for Game Design. I was responsible for the server-side game logic of the project."
          src={projectThree}
          /*githubLink="https://github.com/siew24/energynomics-frontend"*/
          websiteLink="https://energynomics.siew24.xyz/"

        />
        <ProjectsCard
          title="Discord Music Bot"
          des="A music bot using Discord.py made for fun to use within a server."
          src={projectFour}
        />
        <ProjectsCard
          title="DMT Deadline Bot"
          des="A helper bot using Discord.js made to keep track of assignment deadlines with custom reminders within the DMT server."
          src={projectFive}
          githubLink="https://github.com/XMUM-Unofficial-DMT-Discord-Union/deadline-bot"
        />
        <ProjectsCard
          title="MagiCade AR Magic"
          des="An AR storybook in Unity created as the final project for AR course."
          src={projectSix}
          embedID="Ql-zICxZizM"
        />
        <ProjectsCard
          title="MoonBucks"
          des="A mobile application in Android Studio created as the final project for Mobile Application course."
          src={projectSeven}
        />
      </div>
    </section>
  );
};

export default Projects;
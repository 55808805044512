import React from 'react';
import { FaLinkedinIn } from "react-icons/fa";
import { SiGithub, SiGmail, SiUnity, SiUnrealengine, SiGodotengine, SiCplusplus, SiCsharp, SiTypescript, SiCrystal } from "react-icons/si";
const Media = () => {
  return (
    <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between items-center ">
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4 text-center">
          Find me on
        </h2>
        <div className="flex gap-4">
          <span className="bannerIcon">
            <a href="https://github.com/siew24" target="_blank" rel="noopener noreferrer">
              <SiGithub /></a>
          </span>
          <span className="bannerIcon">
            <a href="mailto:siew24.dev@gmail.com">
              <SiGmail /></a>
          </span>
          <span className="bannerIcon">
            <a href="https://www.linkedin.com/in/siew24/" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn /></a>
          </span>
        </div>
      </div>
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4 text-center">
          Proficient Game Engines
        </h2>
        <div className="flex gap-4 justify-center">
          <span className="bannerIcon">
            <SiUnity />
          </span>
          <span className="bannerIcon">
            <SiUnrealengine />
          </span>
          <span className="bannerIcon">
            <SiGodotengine />
          </span>
        </div>
      </div>
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4 text-center">
          PROGRAMMING LANGUAGES
        </h2>
        <div className="flex gap-4">
          <span className="bannerIcon">
            <SiCplusplus />
          </span>
          <span className="bannerIcon">
            <SiCsharp />
          </span>
          <span className="bannerIcon">
            <SiTypescript />
          </span>
          <span className="bannerIcon">
            <SiCrystal />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Media;
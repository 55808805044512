import "react-icons";
import { FaAngleDown } from "react-icons/fa";
import Popup from "reactjs-popup";

const Modal = ({ embedID, children }) => (
    <Popup
        trigger={children}
        modal
        nested
    >
        {close => (
            <div className="text-xs fixed inset-0 bg-black bg-opacity-75 flex flex-col">
                <button className="self-end cursor-pointer block py-[2px] px-[5px] mr-10 h-[64px] text-right top-10 leading-5 text-2xl" onClick={close}>
                    <FaAngleDown color="white" />
                </button>
                <div className="w-full h-[80%] py-[10px] px-[5px]">
                    <iframe className="w-full h-full px-60" src={`https://www.youtube.com/embed/${embedID}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className="w-full py-[10px] px-[5px] m-auto text-center">
                    <Popup
                        trigger={<button className="button"> Trigger </button>}
                        position="top center"
                        nested
                    >
                        <span>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae
                            magni omnis delectus nemo, maxime molestiae dolorem numquam
                            mollitia, voluptate ea, accusamus excepturi deleniti ratione
                            sapiente! Laudantium, aperiam doloribus. Odit, aut.
                        </span>
                    </Popup>
                    <button
                        className="button"
                        onClick={() => {
                            console.log('modal closed ');
                            close();
                        }}
                    >
                        close modal
                    </button>
                </div>
            </div>
        )}
    </Popup>
);

export default Modal;
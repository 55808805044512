import { AiFillAppstore } from "react-icons/ai";
import { FaGamepad } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";

// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <FaGamepad />,
    title: "Game Programming",
    des: "Have completed various projects as the lead game programmer.",
  },
  {
    id: 2,
    icon: <CgWebsite />,
    title: "Web Development",
    des: "Have developed various projects using different technologies and hosted them too.",
  },
  {
    id: 3,
    icon: <AiFillAppstore />,
    title: "App Development",
    des: "Have developed a simple coffee app for Mobile App Development course.",
  },
];

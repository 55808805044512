import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Achievement = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-6 font-titleFont flex flex-col gap-20 md:flex-row"
    >
      <div className="flex-1">
        <div className="py-6 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2005 - Present</p>
          <h2 className="text-4xl font-bold">Accomplishments</h2>
        </div>
        <div className="mt-14 w-full h-[400px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard height="h-1/2"
            title="Library System Research & Development Lead"
            subTitle="Project led by IT Department of Xiamen University Malaysia (June 2022 - June 2023)"
            des="Led the R&D IT team in engineering solutions for a new library system for the school."
          />
          <ResumeCard height="h-1/2"
            title="360 Virtual Library Lead Developer"
            subTitle="Project by IT Department of Xiamen University Malaysia (2023)"
            des="Developed the website frontend as a virtual tour for the library of the school."
          />
        </div>
      </div>
    </motion.div >
  );
};

export default Achievement;

import React from 'react';
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Media from './Media';

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["Game Programmer.", "Web Developer."],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-full flex flex-col gap-20">
      <div className="flex flex-col gap-5 items-center">
        <h1 className="text-6xl font-bold text-white text-center">
          Hi, I'm <span className="text-designColor capitalize">Siew Ee Shin</span>
        </h1>
        <h2 className="text-4xl font-bold text-white text-center">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ffffff"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide text-center">
          "I am a passionate game programmer and web developer, with a focus on creating fun and stable gaming experiences and user-friendly websites."
        </p>
      </div>
      {/* Media */}
      <Media />
    </div>
  );
};

export default LeftBanner;